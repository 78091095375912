@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playpen+Sans:wght@100..800&display=swap");

.app {
  text-align: center;
  font-family: "Open Sans", sans-serif;

  padding: 60px 16px;

  max-width: 1000px;
  font-size: 16px;
}

.system-name {
  font-family: "Playpen Sans", sans-serif;
  font-weight: 600;
  color: #4d46d4;
}

a {
  color: #2a7ae2;
  text-decoration: none;
}

h1 {
  font-family: "Ubuntu", sans-serif;
  font-size: 36px;
  font-weight: 700;
  color: #4d46d4;
  margin-bottom: 12px;
}

h2 {
  font-family: "Ubuntu", sans-serif;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 20px;
}

h3 {
  font-family: "Ubuntu", sans-serif;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 20px;
}

hr {
  margin: 30px 0;
}

pre {
  background-color: #f5f5f5;
  padding: 10px 20px;
  border-radius: 5px;
  overflow-x: auto;
}

.title-highlight {
  color: #4d46d4;
  font-weight: 700;
}

.highlight {
  font-weight: 700;
}
.highlight.feature1 {
  color: #007745;
}
.highlight.feature2 {
  color: #1f4eaa;
}
.highlight.feature3 {
  color: #4c00ad;
}

.author-container {
  padding: 0px 12px;
  font-size: 14px;
}

.author-image {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}

.author-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 50%;

  object-fit: cover;
}

#authors-container {
  max-width: 850px;
  margin: auto;
}

#buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.button {
  gap: 4px;
  padding: 8px 20px;
  border-radius: 100px;
  background-color: #363636;
  color: #fff;
  fill: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;

  display: flex;
}

.button.disabled {
  background-color: #787878;
  cursor: not-allowed;
  pointer-events: none;
}
